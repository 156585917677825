<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import {CtDay7QACount} from '../../../libs/axios';
export default {
  data () {
    return {
      cdata:"",
    }
  },
  methods: {
     init() {
      this.getDay7WageEchartsList();
    },
   getDay7WageEchartsList() {
      var params = window.location.href.split('?');
    var dz_url = params[1].split('&');
      var workid=dz_url[0].split("=")[1];
      this.loading = true;
      CtDay7QACount({workId:workid}).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
        //  alert(JSON.stringify(res.data));
        this.loading = false;
          this.cdata=res.data;
      });
    },
  },
  components: {
    Chart,
  },
  mounted () {
    this.init();
    setInterval(this.init,120000);
  },
}
</script>

<style lang="scss" scoped>
</style>