<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-line"></icon>
        </span>
      </div>
      <div
        class="d-flex jc-center body-box"
        style="height: 90%; font-size: 0.5rem; color: #ffc103"
      >
        <dv-scroll-board :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import { bigGroupTotailInfor } from "../../libs/axios";
export default {
  data() {
    return {
      config: {
        rowNum: 4, //表格行数
        header: [
          "组别",
          "款号",
          "生产人数",
          "计划数",
          "今日产量",
          "欠数",
        ],
        data: [],
        headerHeight: 85,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: false,
        columnWidth: [420, 400, 280, 220, 280, 220],
        align: ["center"],
      },
    };
  },
  components: {},
  mounted() {
    this.init();
    setInterval(this.init, 120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
    getWageList() {
       var params = window.location.href.split('?');
    var dz_url = params[1].split('&');
      var workid=dz_url[0].split("=")[1];
      this.loading = true;
      bigGroupTotailInfor({workId:workid}).then((res) => {
        this.loading = false;
        this.$set(this.config, this.config.data, res.data);
        this.config.data = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 100%;
  min-width: 2.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 10.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    font-size: 0.8rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 1rem;
  }
  .header-item {
    font-size: 0.6rem;
    text-align: center;
  }
  .row-item {
    font-size: 0.8rem;
    color: "#ffc103";
  }
  .ceil {
    color: red;
    text-align: center;
  }
}
</style>